import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <h4>{`-- WIP --`}</h4>
    {
      /*
      <ProjectCard
       title="Tips & Tricks for Gatsby"
       link="https://www.lekoarts.de/gatsby/tips-and-tricks-for-gatsby?utm_source=cara&utm_medium=Theme"
       bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
      >
       A collection of short, but useful quick tips shared by me and the community
       about Gatsby. From GraphQL over local development to deployment.
      </ProjectCard>
      <ProjectCard
       title="Setting  up a yarn Workspace"
       link="https://www.lekoarts.de/javascript/setting-up-a-yarn-workspace-with-typescript-eslint-and-cypress?utm_source=cara&utm_medium=Theme"
       bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
      >
       A robust solution for multi-package repositories.
      </ProjectCard>
      <ProjectCard
       title="Adding a Draft Feature to Gatsby"
       link="https://www.lekoarts.de/garden/adding-a-draft-feature-to-gatsby?utm_source=cara&utm_medium=Theme"
       bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
      >
       A solution with the createSchemaCustomization API.
      </ProjectCard>
      <ProjectCard
       title="Theme UI Plugin for Figma"
       link="https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme"
       bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      >
       The Theme UI plugin for Figma allows for a workflow where Theme UI is the
       starting point both for design & development.
      </ProjectCard>
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      